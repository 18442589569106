import React from "react";
import "./TopicElement.css";

export const TopicElement = (props) => {
  return (
    <div>
      <div className={"topicElement" + props.topicType}>{props.topicTitle}</div>
      <div className="topicElementRectangular"></div>
    </div>
  );
};
