import React from "react";
export const SearchIcon = () => {
  return (
    <svg
      width="19"
      height="20"
      viewBox="0 0 19 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.9848 12.6045C8.4194 14.5318 4.83478 14.2824 2.53317 11.8561C-0.0322145 9.1517 -0.0322144 4.76706 2.53317 2.0627C5.09855 -0.641667 9.25786 -0.641667 11.8232 2.0627C14.1248 4.489 14.3615 8.26781 12.5332 10.9722L18.7908 17.5689L17.2424 19.2011L10.9848 12.6045ZM4.08151 10.2238C5.79177 12.0267 8.56464 12.0267 10.2749 10.2238C11.9851 8.42093 11.9851 5.49783 10.2749 3.69492C8.56464 1.89202 5.79177 1.89202 4.08151 3.69492C2.37126 5.49783 2.37126 8.42093 4.08151 10.2238Z"
        fill="white"
      />
    </svg>
  );
};
