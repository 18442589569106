import React from "react";
import { ArticleElement } from "src/components/article_element/ArticleElement";
import { FeaturedArticleElement } from "../../article_element/FeaturedArticleElement";
import "./FeaturedContentSection.css";
import { SectionTitle } from "src/components/section_elements/SectionTitle";
import { TagElement } from "src/components/article_element/TagElement";
import { ArticleImage } from "src/components/article_element/ArticleImage";
import { Grid } from "@material-ui/core";
const articles = [
  {
    category: "blog",
    articleHeader4:
      "Promising but disputed: is Blockchain the answer to fraud and corruption in education?",
    articleContent:
      "The promise of a technology delivering trust in trust-less environments, of immutability, security, traceability and transparency, has…",
    articleDate: "17 January 2021",
    tagTitle: "Auditing and financial control",
    imgSrc:
      "https://images.unsplash.com/photo-1593642634367-d91a135587b5?ixid=MnwxMjA3fDF8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80",
  },
  {
    category: "U4 issue",
    articleHeader4: "Corruption and migrant returns",
    articleContent:
      "To incentivise the return of rejected asylum-seekers and irregular migrants...",
    articleDate: "17 January 2021",
    tagTitle: "Climate change",
    imgSrc:
      "https://images.unsplash.com/photo-1606787366850-de6330128bfc?ixid=MnwxMjA3fDF8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80",
  },
];
const featuredArticles = [
  {
    category: "U4 issue",
    articleHeader4: "Values education for public integrity",
    articleContent: "What works and what doesn’t",
    articleDate: "17 January 2021",
    tagTitle: "Measurement & Evaluation",
    imgSrc:
      "https://images.unsplash.com/photo-1626197219310-8be29c1316be?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80",
  },
];
const article = articles.map((article, index) => {
  return (
    <div className="articleWithTag" key={index}>
      <ArticleImage key={index + article.imgSrc} src={article.imgSrc} />
      <ArticleElement key={index + article.articleHeader4} article={article} />
      <TagElement key={index + article.tagTitle} title={article.tagTitle} />
    </div>
  );
});
const featuredArticle = featuredArticles.map((featuredArticle, index) => {
  return (
    <div className="articleWithTag" key={index}>
      <FeaturedArticleElement
        key={index + featuredArticle.articleHeader4}
        article={featuredArticle}
      />
      <TagElement
        key={index + featuredArticle.tagTitle}
        title={featuredArticle.tagTitle}
      />
    </div>
  );
});
export const FeaturedContentSection = (props) => {
  return (
    <div className="featured">
      <SectionTitle
        margin="Margin"
        rectangularColor="Dark"
        color="Dark"
        title="featured content"
      />
      <div className="featuredContentWrapper">
        <Grid container spacing={4}>
          <Grid item xs={12} md={8}>
            <div className="featuredContentLeft">{featuredArticle}</div>
          </Grid>
          <Grid item xs={12} md={4}>
            <div className="featuredContentRight">{article}</div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};
