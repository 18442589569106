import "./HomePage.css";
import { FeaturedContentSection } from "src/components/home_page/featured_section/FeaturedContentSection";
import { HomePageHeader } from "src/components/home_page/header_section/HomePageHeader";
import { BlogSection } from "src/components/home_page/blog_section/BlogSection";
import { LatestPublicationsSection } from "src/components/home_page/latest_publications_section/LatestPublicationsSection";
import { EventsSection } from "src/components/home_page/events_section/EventsSection";
import { PartnerSection } from "src/components/home_page/partner_section/PartnerSection";
import { BrowseSection } from "src/components/home_page/browse_section/BrowseSection";
import React from "react";
import { OurPartnersSection } from "./our_partners_section/OurPartnersSection";
import { FooterSection } from "../footer_section/FooterSection";

export const HomePage = (props) => {
  return (
    <div>
      <HomePageHeader />

      <FeaturedContentSection />
      <BlogSection />
      <LatestPublicationsSection />
      <EventsSection />
      <PartnerSection />
      <BrowseSection />
      <OurPartnersSection />
      <FooterSection />
    </div>
  );
};
