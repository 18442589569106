import React from "react";
import "./DateIcon.css";
export const DateIcon = () => {
  return (
    <div className="icon">
      <svg
        width="14"
        height="17"
        viewBox="0 0 14 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M14 3.68712V14.6196C14 15.4446 13.33 16.1104 12.5 16.1104H1.5C0.67 16.1104 0 15.4446 0 14.6196V3.68712C0 2.86221 0.67 2.19632 1.5 2.19632H3V0.457054C3 0.317913 3.11 0.208588 3.25 0.208588H3.75C3.89 0.208588 4 0.317913 4 0.457054V2.19632H10V0.457054C10 0.317913 10.11 0.208588 10.25 0.208588H10.75C10.89 0.208588 11 0.317913 11 0.457054V2.19632H12.5C13.33 2.19632 14 2.86221 14 3.68712ZM13 3.68712C13 3.41877 12.78 3.19019 12.5 3.19019H1.5C1.23 3.19019 1 3.41877 1 3.68712V5.17792H13V3.68712ZM13 6.17178H1V14.6196C1 14.888 1.23 15.1166 1.5 15.1166H12.5C12.78 15.1166 13 14.888 13 14.6196V6.17178ZM3 11.638V8.65645C3 8.3881 3.23 8.15952 3.5 8.15952H6.5C6.78 8.15952 7 8.3881 7 8.65645V11.638C7 11.9064 6.78 12.135 6.5 12.135H3.5C3.23 12.135 3 11.9064 3 11.638ZM4 11.1411H6V9.15338H4V11.1411Z"
          fill="#6F6F6F"
        />
      </svg>
    </div>
  );
};
