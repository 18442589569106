import React from "react";
import "./ArticleImage.css";

export const ArticleImage = (props) => {
  return (
    <div>
      <img className="articleImg" alt="articlepost" src={props.src} />
    </div>
  );
};
