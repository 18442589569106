import React from "react";
import "./CardElement.css";
import { LocationIcon } from "src/components/icons/LocationIcon";
import { DateIcon } from "src/components/icons/DateIcon";
import { ArrowRight } from "src/components/icons/ArrowRight";
import { TopicElement } from "src/components/card_element/TopicElement";
export const CardElement = (props) => {
  return (
    <div className="cardElemMain">
      <div className="cardElem">
        <TopicElement
          topicColor="Light"
          topicType="Bold"
          topicTitle={props.card.category}
        />
        <h4>{props.card.heading}</h4>
        <p className="cardContent">{props.card.content}</p>
        <div className="location">
          <LocationIcon />
          {props.card.location}
        </div>
        <div className="cardFooter">
          <div className="cardDate">
            <DateIcon />
            <div>{props.card.date}</div>
          </div>
          <div className="more">
            View more
            <ArrowRight svgColor="#162063" />
          </div>
        </div>
      </div>
    </div>
  );
};
