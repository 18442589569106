import React from "react";
import "./ArticleElement.css";
export const ArticleElement = (props) => {
  return (
    <div className="articleElemMain">
      <div className="articleElem">
        <div className="elementCategory">{props.article.category}</div>
        <div className="elementCategoryRectangular"></div>
        <h4>{props.article.articleHeader4}</h4>
        <p className="articleContent">{props.article.articleContent}</p>
        <p className="articleDate">{props.article.articleDate}</p>
      </div>
    </div>
  );
};
