import React from "react";

export const MailIcon = (props) => {
  return (
    <svg
      width="20"
      height="16"
      viewBox="0 0 20 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.833252 2.11279C0.833252 1.07786 1.65406 0.238892 2.66659 0.238892H17.3333C18.3458 0.238892 19.1666 1.07786 19.1666 2.11279V13.3561C19.1666 14.3911 18.3458 15.23 17.3333 15.23H2.66659C1.65406 15.23 0.833252 14.3911 0.833252 13.3561V2.11279ZM17.3333 3.86348V13.3561H2.66659V3.86348L9.99992 8.86053L17.3333 3.86348ZM16.5974 2.11279H3.40248L9.99992 6.60839L16.5974 2.11279Z"
        fill="#E4F1FA"
      />
    </svg>
  );
};
