import React from "react";
export const Icon = () => {
  return (
    <svg
      width="240"
      height="33"
      viewBox="0 0 240 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0)">
        <path
          d="M11.2995 0C14.4482 0 17.3777 0.338764 19.3905 1.21557V7.69194C15.5443 6.11769 13.3123 6.01805 11.2995 6.01805C7.61272 6.01805 6.31736 9.34591 6.31736 14.3875C6.31736 19.4291 7.59279 22.757 11.2995 22.757C13.3123 22.757 15.5443 22.6374 19.3905 21.0831V27.5595C17.3777 28.4363 14.4482 28.775 11.2995 28.775C2.75014 28.7352 0 22.8965 0 14.3676C0 5.85863 2.75014 0 11.2995 0Z"
          fill="white"
        />
        <path
          d="M39.3191 24.5306H34.875L30.132 13.6702L30.3114 28.7551H24.0737V0.0598145H30.6103L37.107 15.2046L43.6038 0.0598145H50.1403V28.6754H43.9027L44.082 13.6303L39.3191 24.5306Z"
          fill="white"
        />
        <path
          d="M55.3018 28.6754V0.0598145H61.639V28.6754H55.3018Z"
          fill="white"
        />
        <path
          d="M71.165 0.179199H70.4675V28.755H71.165V0.179199Z"
          fill="white"
        />
        <path
          d="M109.468 24.4108H112.697V28.5956H109.468V32.9198H104.865V28.5956H89.8982L109.468 0.179199V24.4108ZM104.865 24.4108V14.208L97.8497 24.4108H104.865Z"
          fill="white"
        />
        <path
          d="M85.8526 21.6612C84.9956 20.4456 84.5771 18.5326 84.5771 15.9221V0.199463H79.9736V17.1775C79.9736 20.3858 80.9701 23.0362 82.9828 25.1086C84.338 26.5234 85.8924 27.4999 87.6461 28.0379L90.2767 24.2119C88.4034 24.0126 86.9486 23.1557 85.8526 21.6612Z"
          fill="white"
        />
        <path
          d="M102.533 0.199219H97.9292V13.0723L102.533 6.39661V0.199219Z"
          fill="white"
        />
        <path
          d="M122.8 13.6097H119.711L119.093 16.1007H116.781L120.07 4.40332H122.441L125.749 16.1007H123.438L122.8 13.6097ZM120.189 11.7964H122.342L121.285 7.65147H121.246L120.189 11.7964Z"
          fill="white"
        />
        <path
          d="M134.837 16.1012H132.565L128.998 8.62849L128.958 8.64842V16.1012H126.666V4.42383H128.938L132.505 11.8966L132.545 11.8766V4.42383H134.817V16.1012H134.837Z"
          fill="white"
        />
        <path
          d="M143.267 6.23712H140.636V16.1011H138.364V6.23712H135.753V4.40381H143.286V6.23712H143.267Z"
          fill="white"
        />
        <path
          d="M146.754 16.1012H144.482V4.42383H146.754V16.1012Z"
          fill="white"
        />
        <path
          d="M152.733 11.9962H148.747V10.1628H152.733V11.9962Z"
          fill="white"
        />
        <path
          d="M161.999 12.335L162.019 12.3748C162.039 13.6502 161.72 14.6266 161.103 15.2842C160.485 15.9418 159.568 16.2606 158.352 16.2606C157.137 16.2606 156.18 15.882 155.463 15.1447C154.745 14.4074 154.387 13.3513 154.387 11.9962V8.52885C154.387 7.17379 154.745 6.11764 155.443 5.38033C156.14 4.64302 157.077 4.2644 158.253 4.2644C159.488 4.2644 160.425 4.60317 161.063 5.26077C161.7 5.91837 162.019 6.91474 162.039 8.21001L162.019 8.24987H159.827C159.867 7.4727 159.747 6.93466 159.508 6.5959C159.269 6.25714 158.851 6.09772 158.253 6.09772C157.735 6.09772 157.336 6.29699 157.077 6.69554C156.798 7.09408 156.659 7.71183 156.659 8.52885V12.0361C156.659 12.8531 156.798 13.4708 157.097 13.8694C157.396 14.2679 157.814 14.4672 158.372 14.4672C158.93 14.4672 159.309 14.3078 159.528 13.9889C159.747 13.6701 159.847 13.1321 159.807 12.3549H161.999V12.335Z"
          fill="white"
        />
        <path
          d="M171.286 11.9164C171.286 13.2914 170.908 14.3675 170.17 15.1247C169.433 15.9019 168.436 16.2805 167.201 16.2805C165.965 16.2805 164.969 15.9019 164.231 15.1247C163.494 14.3475 163.115 13.2914 163.115 11.9164V8.6284C163.115 7.25341 163.494 6.19726 164.231 5.4201C164.969 4.64293 165.965 4.24438 167.181 4.24438C168.417 4.24438 169.393 4.64293 170.15 5.4201C170.908 6.19726 171.266 7.27334 171.266 8.6284V11.9164H171.286ZM169.014 8.60847C169.014 7.77152 168.855 7.13385 168.556 6.71537C168.237 6.2969 167.799 6.0777 167.201 6.0777C166.603 6.0777 166.145 6.2969 165.846 6.71537C165.547 7.13385 165.387 7.77152 165.387 8.60847V11.9164C165.387 12.7733 165.547 13.411 165.846 13.8294C166.145 14.2479 166.603 14.4671 167.201 14.4671C167.799 14.4671 168.237 14.2479 168.556 13.8294C168.875 13.411 169.014 12.7733 169.014 11.9164V8.60847Z"
          fill="white"
        />
        <path
          d="M175.053 11.4582V16.1012H172.781V4.42383H176.587C177.743 4.42383 178.64 4.72274 179.297 5.34048C179.955 5.95823 180.274 6.79518 180.274 7.87125C180.274 8.489 180.134 9.00711 179.855 9.44551C179.576 9.88391 179.178 10.2426 178.66 10.5017C179.258 10.7009 179.696 11.0397 179.955 11.518C180.214 11.9962 180.354 12.5741 180.354 13.2915V14.1284C180.354 14.4473 180.393 14.786 180.473 15.1647C180.553 15.5233 180.672 15.8023 180.872 15.9617V16.1212H178.5C178.321 15.9418 178.201 15.6628 178.142 15.2842C178.102 14.9056 178.062 14.5071 178.062 14.1085V13.3114C178.062 12.7335 177.942 12.2752 177.703 11.9564C177.464 11.6375 177.125 11.4781 176.687 11.4781H175.053V11.4582ZM175.053 9.64479H176.587C177.045 9.64479 177.404 9.50529 177.643 9.22631C177.882 8.94733 178.002 8.52886 178.002 8.01075C178.002 7.47271 177.882 7.05423 177.643 6.7354C177.404 6.41656 177.045 6.25714 176.587 6.25714H175.053V9.64479Z"
          fill="white"
        />
        <path
          d="M184.399 11.4582V16.1012H182.127V4.42383H185.934C187.09 4.42383 187.986 4.72274 188.644 5.34048C189.302 5.95823 189.621 6.79518 189.621 7.87125C189.621 8.489 189.481 9.00711 189.202 9.44551C188.923 9.88391 188.525 10.2426 188.006 10.5017C188.604 10.7009 189.043 11.0397 189.302 11.518C189.561 11.9962 189.7 12.5741 189.7 13.2915V14.1284C189.7 14.4473 189.74 14.786 189.82 15.1647C189.9 15.5233 190.019 15.8023 190.219 15.9617V16.1212H187.847C187.668 15.9418 187.548 15.6628 187.488 15.2842C187.448 14.9056 187.409 14.5071 187.409 14.1085V13.3114C187.409 12.7335 187.289 12.2752 187.05 11.9564C186.811 11.6375 186.472 11.4781 186.033 11.4781H184.399V11.4582ZM184.399 9.64479H185.934C186.392 9.64479 186.751 9.50529 186.99 9.22631C187.229 8.94733 187.349 8.52886 187.349 8.01075C187.349 7.47271 187.229 7.05423 186.99 6.7354C186.751 6.41656 186.392 6.25714 185.934 6.25714H184.399V9.64479Z"
          fill="white"
        />
        <path
          d="M199.246 4.42383V12.3748C199.246 13.6303 198.887 14.5868 198.15 15.2643C197.413 15.9418 196.456 16.2806 195.28 16.2806C194.104 16.2806 193.148 15.9418 192.411 15.2643C191.673 14.5868 191.314 13.6303 191.314 12.3748V4.42383H193.586V12.3748C193.586 13.0922 193.726 13.6103 194.025 13.9491C194.324 14.2879 194.742 14.4473 195.26 14.4473C195.818 14.4473 196.237 14.2879 196.516 13.969C196.815 13.6502 196.954 13.1121 196.954 12.3748V4.42383H199.246Z"
          fill="white"
        />
        <path
          d="M203.172 11.9165V16.1212H200.88V4.42383H204.946C206.121 4.42383 207.038 4.76259 207.716 5.46005C208.393 6.1575 208.732 7.05423 208.732 8.17016C208.732 9.28609 208.393 10.2028 207.716 10.8803C207.038 11.5578 206.121 11.9165 204.965 11.9165H203.172ZM203.172 10.0832H204.946C205.424 10.0832 205.802 9.90384 206.062 9.54515C206.321 9.18646 206.44 8.72813 206.44 8.17016C206.44 7.6122 206.321 7.15387 206.062 6.77525C205.802 6.41656 205.424 6.21729 204.946 6.21729H203.172V10.0832Z"
          fill="white"
        />
        <path
          d="M217.062 6.23712H214.432V16.1011H212.16V6.23712H209.549V4.40381H217.082V6.23712H217.062Z"
          fill="white"
        />
        <path
          d="M220.57 16.1012H218.298V4.42383H220.57V16.1012Z"
          fill="white"
        />
        <path
          d="M230.335 11.9164C230.335 13.2914 229.956 14.3675 229.219 15.1247C228.481 15.9019 227.485 16.2805 226.249 16.2805C225.014 16.2805 224.017 15.9019 223.28 15.1247C222.543 14.3475 222.164 13.2914 222.164 11.9164V8.6284C222.164 7.25341 222.543 6.19726 223.28 5.4201C224.017 4.64293 225.014 4.24438 226.229 4.24438C227.445 4.24438 228.442 4.64293 229.199 5.4201C229.956 6.19726 230.315 7.27334 230.315 8.6284V11.9164H230.335ZM228.063 8.60847C228.063 7.77152 227.903 7.13385 227.605 6.71537C227.286 6.2969 226.847 6.0777 226.249 6.0777C225.652 6.0777 225.193 6.2969 224.894 6.71537C224.595 7.13385 224.436 7.77152 224.436 8.60847V11.9164C224.436 12.7733 224.595 13.411 224.894 13.8294C225.193 14.2479 225.652 14.4671 226.249 14.4671C226.847 14.4671 227.286 14.2479 227.605 13.8294C227.923 13.411 228.063 12.7733 228.063 11.9164V8.60847Z"
          fill="white"
        />
        <path
          d="M240 16.1012H237.728L234.161 8.62849L234.121 8.64842V16.1012H231.829V4.42383H234.121L237.688 11.8966L237.728 11.8766V4.42383H240V16.1012Z"
          fill="white"
        />
        <path
          d="M121.823 27.8582C121.784 27.6589 121.764 27.4397 121.764 27.2006V26.4234C121.764 25.9053 121.664 25.4669 121.465 25.1082C121.305 24.8293 121.086 24.63 120.787 24.4706C121.046 24.3111 121.265 24.0919 121.445 23.813C121.664 23.4543 121.784 23.0358 121.784 22.5775C121.784 21.8003 121.564 21.2025 121.146 20.784C120.727 20.3655 120.13 20.1663 119.352 20.1663H116.841V28.6553H117.818V24.9488H119.512C119.91 24.9488 120.209 25.0684 120.428 25.3274C120.648 25.5865 120.767 25.9651 120.767 26.4234V27.1807C120.767 27.4597 120.787 27.7187 120.827 27.9778C120.867 28.2368 120.967 28.456 121.106 28.6154L121.146 28.6752H122.063V28.3365L122.023 28.2966C121.943 28.177 121.883 28.0376 121.823 27.8582ZM120.807 22.5974C120.807 23.0557 120.688 23.4343 120.448 23.6934C120.209 23.9525 119.811 24.0919 119.293 24.0919H117.838V21.0829H119.372C119.87 21.0829 120.229 21.2025 120.468 21.4615C120.688 21.7206 120.807 22.0992 120.807 22.5974Z"
          fill="white"
        />
        <path
          d="M124.235 24.71H127.463V23.8332H124.235V21.0833H127.902V20.1865H123.258V28.6756H127.942V27.7988H124.235V24.71Z"
          fill="white"
        />
        <path
          d="M131.369 23.9329C130.732 23.7336 130.273 23.4945 130.034 23.2554C129.775 22.9963 129.655 22.6974 129.655 22.3387C129.655 21.9402 129.775 21.6213 130.034 21.3623C130.293 21.1032 130.652 20.9637 131.13 20.9637C131.628 20.9637 131.987 21.1231 132.266 21.442C132.545 21.7608 132.684 22.1793 132.684 22.6775V22.8369H133.561L133.641 22.6974V22.6575C133.661 21.9402 133.422 21.3423 132.963 20.8442C132.505 20.346 131.887 20.0869 131.13 20.0869C130.413 20.0869 129.815 20.3061 129.356 20.7246C128.898 21.1431 128.659 21.701 128.659 22.3786C128.659 23.0362 128.878 23.5543 129.277 23.9528C129.675 24.3314 130.273 24.6303 131.03 24.8695C131.668 25.0687 132.107 25.3079 132.346 25.5669C132.585 25.826 132.704 26.1448 132.704 26.5633C132.704 26.9818 132.565 27.3205 132.286 27.5796C132.007 27.8386 131.628 27.9582 131.15 27.9582C130.672 27.9582 130.253 27.8187 129.914 27.5397C129.576 27.2607 129.416 26.8423 129.416 26.2445V26.085H128.519L128.48 26.2245V26.2644C128.46 27.1013 128.719 27.739 129.277 28.1774C129.815 28.6158 130.453 28.835 131.17 28.835C131.907 28.835 132.505 28.6357 132.983 28.2173C133.462 27.7988 133.701 27.2408 133.701 26.5434C133.701 25.8858 133.502 25.3278 133.083 24.9293C132.704 24.4908 132.126 24.172 131.369 23.9329Z"
          fill="white"
        />
        <path
          d="M137.149 20.0669C136.312 20.0669 135.654 20.3459 135.176 20.9038C134.697 21.4618 134.458 22.2589 134.458 23.2752V25.5668C134.458 26.6031 134.697 27.4001 135.176 27.9382C135.654 28.4961 136.332 28.7751 137.149 28.7751C138.006 28.7751 138.683 28.4961 139.161 27.9382C139.64 27.3802 139.899 26.5831 139.899 25.5668V23.2752C139.899 22.2589 139.66 21.4419 139.161 20.9038C138.683 20.3459 138.006 20.0669 137.149 20.0669ZM138.942 23.2752V25.5868C138.942 26.3639 138.783 26.9617 138.484 27.3404C138.185 27.719 137.746 27.8983 137.169 27.8983C136.611 27.8983 136.192 27.719 135.893 27.3404C135.594 26.9617 135.435 26.3639 135.435 25.5868V23.2752C135.435 22.498 135.594 21.9201 135.893 21.5415C136.192 21.1629 136.611 20.9836 137.169 20.9836C137.746 20.9836 138.185 21.1629 138.484 21.5415C138.783 21.9201 138.942 22.498 138.942 23.2752Z"
          fill="white"
        />
        <path
          d="M145.08 26.085C145.08 26.7227 144.941 27.181 144.642 27.4799C144.343 27.7788 143.964 27.9183 143.486 27.9183C143.027 27.9183 142.649 27.7589 142.37 27.46C142.091 27.1412 141.931 26.6828 141.931 26.085V20.1865H140.975V26.085C140.975 26.9618 141.214 27.6393 141.672 28.1176C142.131 28.5959 142.748 28.8151 143.486 28.8151C144.243 28.8151 144.861 28.5759 145.339 28.1176C145.818 27.6393 146.057 26.9618 146.057 26.085V20.1865H145.08V26.085Z"
          fill="white"
        />
        <path
          d="M152.394 27.8584C152.354 27.6592 152.334 27.44 152.334 27.2008V26.4237C152.334 25.9056 152.235 25.4672 152.035 25.1085C151.876 24.8295 151.657 24.6302 151.358 24.4708C151.617 24.3114 151.836 24.0922 152.015 23.8132C152.235 23.4545 152.354 23.036 152.354 22.5777C152.354 21.8005 152.135 21.2027 151.716 20.7843C151.298 20.3658 150.7 20.1665 149.923 20.1665H147.412V28.6555H148.388V24.9491H150.082C150.481 24.9491 150.78 25.0686 150.999 25.3277C151.218 25.5867 151.338 25.9654 151.338 26.4237V27.1809C151.338 27.4599 151.358 27.719 151.398 27.978C151.437 28.2371 151.537 28.4563 151.677 28.6157L151.716 28.6755H152.633V28.3367L152.593 28.2969C152.514 28.1773 152.434 28.0378 152.394 27.8584ZM151.358 22.5976C151.358 23.056 151.238 23.4346 150.999 23.6936C150.76 23.9527 150.381 24.0922 149.843 24.0922H148.388V21.0832H149.923C150.421 21.0832 150.78 21.2027 151.019 21.4618C151.238 21.7208 151.358 22.0995 151.358 22.5976Z"
          fill="white"
        />
        <path
          d="M154.945 21.5415C155.224 21.1629 155.622 20.9636 156.14 20.9636C156.659 20.9636 157.017 21.1031 157.276 21.3821C157.535 21.6611 157.675 22.1194 157.695 22.7172V22.8567H158.552L158.632 22.7172V22.6774C158.632 21.8205 158.392 21.1629 157.954 20.7245C157.515 20.2861 156.898 20.0669 156.14 20.0669C155.343 20.0669 154.706 20.3658 154.227 20.9238C153.769 21.4817 153.53 22.2788 153.53 23.2951V25.5668C153.53 26.5831 153.769 27.3802 154.227 27.9382C154.706 28.4961 155.343 28.7951 156.14 28.7951C156.898 28.7951 157.515 28.5759 157.954 28.1175C158.392 27.6592 158.612 27.0215 158.632 26.1846V26.1447L158.552 26.0052H157.695V26.1447C157.675 26.7824 157.535 27.2407 157.276 27.5197C157.017 27.7987 156.639 27.9382 156.14 27.9382C155.622 27.9382 155.224 27.7588 154.945 27.3603C154.646 26.9617 154.506 26.3639 154.506 25.5868V23.2951C154.506 22.518 154.646 21.9401 154.945 21.5415Z"
          fill="white"
        />
        <path
          d="M160.784 24.71H164.012V23.8332H160.784V21.0833H164.47V20.1865H159.827V28.6756H164.49V27.7988H160.784V24.71Z"
          fill="white"
        />
        <path
          d="M169.014 21.5415C169.293 21.1629 169.692 20.9636 170.21 20.9636C170.728 20.9636 171.087 21.1031 171.346 21.3821C171.605 21.6611 171.745 22.1194 171.764 22.7172V22.8567H172.621L172.701 22.7172V22.6774C172.701 21.8205 172.462 21.1629 172.024 20.7245C171.585 20.2861 170.967 20.0669 170.21 20.0669C169.413 20.0669 168.775 20.3658 168.297 20.9238C167.839 21.4817 167.599 22.2788 167.599 23.2951V25.5668C167.599 26.5831 167.839 27.3802 168.297 27.9382C168.775 28.4961 169.413 28.7951 170.21 28.7951C170.967 28.7951 171.585 28.5759 172.024 28.1175C172.462 27.6791 172.681 27.0215 172.701 26.1846V26.1447L172.621 26.0052H171.764V26.1447C171.745 26.7824 171.605 27.2407 171.346 27.5197C171.087 27.7987 170.708 27.9382 170.21 27.9382C169.692 27.9382 169.293 27.7588 169.014 27.3603C168.715 26.9617 168.576 26.3639 168.576 25.5868V23.2951C168.576 22.518 168.715 21.9401 169.014 21.5415Z"
          fill="white"
        />
        <path
          d="M174.854 24.71H178.082V23.8332H174.854V21.0833H178.54V20.1865H173.897V28.6756H178.56V27.7988H174.854V24.71Z"
          fill="white"
        />
        <path
          d="M183.901 26.6629L180.453 20.1865H179.537V28.6756H180.513V22.1992L183.961 28.6756H184.878V20.1865H183.901V26.6629Z"
          fill="white"
        />
        <path
          d="M185.595 21.0833H187.867V28.6756H188.843V21.0833H191.135V20.1865H185.595V21.0833Z"
          fill="white"
        />
        <path
          d="M196.994 27.8584C196.954 27.6592 196.934 27.44 196.934 27.2008V26.4237C196.934 25.9056 196.834 25.4672 196.635 25.1085C196.476 24.8295 196.256 24.6302 195.958 24.4708C196.217 24.3114 196.436 24.0922 196.615 23.8132C196.834 23.4545 196.954 23.036 196.954 22.5777C196.954 21.8005 196.735 21.2027 196.316 20.7843C195.898 20.3658 195.3 20.1665 194.523 20.1665H192.012V28.6555H192.988V24.9491H194.682C195.081 24.9491 195.38 25.0686 195.599 25.3277C195.818 25.5867 195.938 25.9654 195.938 26.4237V27.1809C195.938 27.4599 195.958 27.719 195.997 27.978C196.037 28.2371 196.137 28.4563 196.276 28.6157L196.316 28.6755H197.233V28.3367L197.193 28.2969C197.093 28.1773 197.034 28.0378 196.994 27.8584ZM195.958 22.5976C195.958 23.056 195.838 23.4346 195.599 23.6936C195.36 23.9527 194.981 24.0922 194.443 24.0922H192.988V21.0832H194.523C195.021 21.0832 195.38 21.2027 195.619 21.4618C195.838 21.7208 195.958 22.0995 195.958 22.5976Z"
          fill="white"
        />
        <path
          d="M199.386 27.7988V24.71H202.614V23.8332H199.386V21.0833H203.072V20.1865H198.429V28.6756H203.092V27.7988H199.386Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="240" height="32.9" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
