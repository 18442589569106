import React from "react";
import "./EventsSection.css";
import { SectionTitle } from "src/components/section_elements/SectionTitle";
import { CardElement } from "src/components/card_element/CardElement";
import { Grid } from "@material-ui/core";
const cards = [
  {
    id: 0,
    category: "Online course",
    heading:
      "Piracy in the Gulf of Guinea: Time to bring anti-corruption on board capacity building programmes",
    content:
      "Many are worried the UK’s new FCDO will relegate development assistance...",
    date: "17 January 2021",
    location: "",
  },
  {
    id: 1,
    category: "Online course",
    heading:
      "Piracy in the Gulf of Guinea: Time to bring anti-corruption on board capacity building programmes",
    content:
      "Many are worried the UK’s new FCDO will relegate development assistance...",
    date: "17 January 2021",
    location: "",
  },
  {
    id: 2,
    category: "Online course",
    heading:
      "Piracy in the Gulf of Guinea: Time to bring anti-corruption on board capacity building programmes",
    content:
      "Many are worried the UK’s new FCDO will relegate development assistance...",
    date: "17 January 2021",
    location: "",
  },
];

const card = cards.map((card, index) => {
  return (
    <Grid key={index} item md={4} sm={12}>
      <CardElement key={index + card.heading} card={card} />
    </Grid>
  );
});
export const EventsSection = (props) => {
  return (
    <div className="learningMain">
      <div className="learningSection">
        <SectionTitle
          margin="Margin"
          rectangularColor="Dark"
          color="Dark"
          title="Learning and events"
        />
        <div className="learningWrapper">
          <Grid container spacing={4}>
            {card}
          </Grid>
        </div>
      </div>
    </div>
  );
};
