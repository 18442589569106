import React from "react";
import "./BlogSection.css";
import { SectionTitle } from "src/components/section_elements/SectionTitle";
import { ArticleElement } from "src/components/article_element/ArticleElement";
import { TagElement } from "src/components/article_element/TagElement";
import { SectionFooter } from "src/components/section_elements/SectionFooter";
import { ArticleImage } from "src/components/article_element/ArticleImage";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
// @ts-ignore

const handleOnDragStart = (e) => e.preventDefault();
const responsive = {
  0: { items: 1 },
  568: { items: 2 },
  1024: { items: 3 },
};
export const articles = [
  {
    id: 0,
    category: "blog",
    articleHeader4:
      "Piracy in the Gulf of Guinea: Time to bring anti-corruption on board capacity building programmes",
    articleContent:
      "Many are worried the UK’s new FCDO will relegate development assistance...",
    articleDate: "17 January 2021",
    tagTitle: "Migration",
    imgSrc:
      "https://images.unsplash.com/photo-1626183834780-2d2ddee5702d?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80",
  },
  {
    id: 1,
    category: "blog",
    articleDate: "17 January 2021",
    articleHeader4:
      "Downward accountability in humanitarian aid: lessons from a hotline for refugees",
    articleContent:
      "The risks of corruption in humanitarian response can be reduced by aid organisations being answerable to their beneficiaries.",
    tagTitle: "Development cooperation",
    imgSrc:
      "https://images.unsplash.com/photo-1626244297294-c1aecb88f542?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1951&q=80",
  },
  {
    id: 2,
    category: "blog",
    articleDate: "17 January 2021",
    articleHeader4:
      "Anti-corruption tools give climate change practitioners a better chance of limiting global warming",
    articleContent:
      "Donors and other funders are investing vast sums in interventions to mitigate climate change...",
    tagTitle: "Climate change",
    imgSrc:
      "https://images.unsplash.com/photo-1626238586896-3e005566e0ae?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80",
  },
];

const article = articles.map((article, index) => {
  return (
    <div
      className="alice-carousel__stage-item articleWithTag"
      onDragStart={handleOnDragStart}
      key={index}
    >
      <ArticleImage key={index + article.imgSrc} src={article.imgSrc} />
      <ArticleElement key={index + article.articleHeader4} article={article} />
      <TagElement key={index + article.tagTitle} title={article.tagTitle} />
    </div>
  );
});

export const BlogSection = (props) => {
  return (
    <div className="blogMain">
      <div className="blogSection">
        <SectionTitle
          margin="Margin"
          rectangularColor="Dark"
          color="Dark"
          title="from the blog"
        />
        <div className="blogWrapper">
          <AliceCarousel
            mouseTracking
            items={article}
            controlsStrategy="alternate"
            responsive={responsive}
          />
        </div>
        <SectionFooter />
      </div>
    </div>
  );
};
