import React from "react";

export const TwitterIcon = (props) => {
  return (
    <svg
      width="18"
      height="17"
      viewBox="0 0 18 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18 2.51562C17.3378 2.844 16.626 3.06592 15.8791 3.16571C16.6414 2.65475 17.227 1.84568 17.5027 0.881568C16.7891 1.35474 15.9989 1.6983 15.1576 1.88341C14.4841 1.08103 13.5244 0.579712 12.4622 0.579712C10.4227 0.579712 8.76914 2.42816 8.76914 4.70818C8.76914 5.03174 8.80184 5.34686 8.86483 5.64906C5.79565 5.47686 3.0745 3.83322 1.25314 1.33537C0.935245 1.94512 0.753131 2.65432 0.753131 3.41096C0.753131 4.84333 1.40506 6.107 2.39599 6.84742C1.79069 6.82587 1.22119 6.64021 0.723342 6.33098C0.722989 6.34823 0.722989 6.36546 0.722989 6.38287C0.722989 8.38325 1.99595 10.0518 3.6853 10.4312C3.37544 10.5255 3.04919 10.576 2.7124 10.576C2.47444 10.576 2.2431 10.5501 2.01757 10.502C2.48753 12.1421 3.85133 13.3358 5.46733 13.369C4.20346 14.4765 2.61114 15.1365 0.880931 15.1365C0.58285 15.1365 0.288899 15.1169 -2.68422e-08 15.0787C1.63429 16.2501 3.57544 16.9337 5.6609 16.9337C12.4535 16.9337 16.168 10.6427 16.168 5.1869C16.168 5.00793 16.1644 4.82987 16.1573 4.65276C16.8789 4.07067 17.505 3.34355 18 2.51562Z"
        fill="#E4F1FA"
      />
    </svg>
  );
};
