import React from "react";
import "./LatestPublicationsSection.css";
import { SectionTitle } from "src/components/section_elements/SectionTitle";
import { ArticleElement } from "src/components/article_element/ArticleElement";
import { TagElement } from "src/components/article_element/TagElement";
import { SectionFooter } from "src/components/section_elements/SectionFooter";
import Grid from "@material-ui/core/Grid";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
const publications = [
  {
    id: 0,
    category: "U4 issue",
    articleHeader4: "The credibility of corruption statistics",
    articleContent: "A critical review of ten global estimates",
    articleDate: "17 January 2021",
    tagTitle: "Measurement & evaluatioon",
  },
  {
    id: 1,
    category: "U4 brief",
    articleHeader4: "Reassessing donor performance in anti-corruption",
    articleContent: "Pathways to more effective practice",
    articleDate: "17 January 2021",
    tagTitle: "Development cooperation",
  },
  {
    id: 2,
    category: "U4 issue",
    articleHeader4: "Corrupt networks in the Indonesian forestry sector",
    articleContent: "Politics and pulp in Pelalawan, Riau",
    articleDate: "17 January 2021",
    tagTitle: "Natural resources & energy",
  },
  {
    id: 3,
    category: "U4 issue",
    articleHeader4: "Health sector corruption",
    articleContent: "Practical recommendations for donors",
    articleDate: "17 January 2021",
    tagTitle: "Health",
  },
];
const article = publications.map((article, index) => {
  return (
    <Grid className="articleWithTag" key={index} item sm={12} md={4} lg={12}>
      <ArticleElement key={index + article.articleHeader4} article={article} />
      <TagElement key={index + article.tagTitle} title={article.tagTitle} />
    </Grid>
  );
});
export const LatestPublicationsSection = (props) => {
  return (
    <div className="publicationsMain">
      <div className="latestPublicationsSection">
        <SectionTitle
          margin="Margin"
          rectangularColor="Dark"
          color="Dark"
          title="latest Publications"
        />
        <div className="latestPublicationsBlogWrapper">
          <Grid container spacing={4}>
            <AliceCarousel
              mouseTracking
              items={article}
              controlsStrategy="alternate"
            />
          </Grid>
        </div>
        <SectionFooter />
      </div>
    </div>
  );
};
