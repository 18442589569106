import React from "react";
export const LocationIcon = () => {
  return (
    <svg
      width="12"
      height="17"
      viewBox="0 0 12 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 6.35583C12 8.76098 11.16 9.42687 6.62 15.9764C6.32 16.4038 5.68 16.4038 5.39 15.9764C0.84 9.42687 0 8.76098 0 6.35583C0 3.06614 2.69 0.392639 6 0.392639C9.31 0.392639 12 3.06614 12 6.35583ZM11 6.35583C11 5.03399 10.48 3.78172 9.54 2.83755C8.59 1.90331 7.34 1.3865 6 1.3865C4.67 1.3865 3.41 1.90331 2.46 2.83755C1.52 3.78172 1 5.03399 1 6.35583C1 8.36344 1.65 8.85043 6 15.1118C10.35 8.85043 11 8.3535 11 6.35583ZM9 6.35583C9 7.99571 7.65 9.33743 6 9.33743C4.35 9.33743 3 7.99571 3 6.35583C3 4.71595 4.35 3.37423 6 3.37423C7.65 3.37423 9 4.71595 9 6.35583ZM8 6.35583C8 5.26258 7.1 4.3681 6 4.3681C4.9 4.3681 4 5.26258 4 6.35583C4 7.44908 4.9 8.34356 6 8.34356C7.1 8.34356 8 7.44908 8 6.35583Z"
        fill="#6F6F6F"
      />
    </svg>
  );
};
