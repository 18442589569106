import React from "react";
import "src/components/navigation/NavBar.css";
import { Link } from "react-router-dom";
import { Icon } from "../icons/Icon";
import { SearchIcon } from "../icons/SearchIcon";

export const NavBar = () => {
  return (
    <div>
      <div className="navBackground"></div>
      <div className="navMain">
        <nav>
          <ul className="navBarUl">
            <li className="link">
              <Link to="/">
                <Icon />
              </Link>
            </li>
            <li className="link">
              <Link to="/about">Research topics</Link>
            </li>
            <li className="link">
              <Link to="/users">learning & events</Link>
            </li>
            <li className="link">
              <Link to="/">About us</Link>
            </li>
            <li className="link">
              <Link to="/">The u4 blog</Link>
            </li>
            <li className="link">
              <Link to="/">Publications</Link>
            </li>
            <li className="link">
              <Link to="/">Search</Link>
            </li>
            <li className="link">
              <Link to="/">
                <SearchIcon />
              </Link>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
};
