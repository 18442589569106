import React from "react";
import "./SectionFooter.css";
import { ArrowRight } from "src/components/icons/ArrowRight";

export const SectionFooter = (props) => {
  return (
    <div className="sectionFooter">
      <div className="sectionFooterLeft">
        <div>View ALL</div>
        <ArrowRight svgColor="#333333" />
      </div>
    </div>
  );
};
