import React from "react";
import "./ArrowRight.css";
export const ArrowRight = (props) => {
  return (
    <div className="ArrowRight">
      <svg
        width="7"
        height="11"
        viewBox="0 0 7 11"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1 1L5 5.73684L1 10"
          stroke={props.svgColor}
          strokeWidth="1.5"
        />
      </svg>
    </div>
  );
};
