import React from "react";

export const FacebookIcon = (props) => {
  return (
    <svg
      style={{ paddingLeft: "0.75px", paddingBottom: "1px" }}
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.04831 0.557495C3.60359 0.557495 0 4.2408 0 8.78385C0 12.8892 2.94276 16.292 6.79104 16.9115V11.1623H4.74656V8.78385H6.79104V6.97141C6.79104 4.90803 7.99277 3.77021 9.83038 3.77021C10.711 3.77021 11.6334 3.93064 11.6334 3.93064V5.9537H10.616C9.61789 5.9537 9.30558 6.58884 9.30558 7.23961V8.78221H11.536L11.1794 11.1607H9.30558V16.9098C13.1539 16.2936 16.0966 12.89 16.0966 8.78385C16.0966 4.2408 12.493 0.557495 8.04831 0.557495Z"
        fill="#E4F1FA"
      />
    </svg>
  );
};
