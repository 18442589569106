import React from "react";
import "./OurPartnersSection.css";
import { SectionTitle } from "src/components/section_elements/SectionTitle";
import { Grid } from "@material-ui/core";

const images = [
  { imgSrc: "/images/canada_4_logo_black_and_white_3.png" },
  { imgSrc: "/images/sida_logo_1.png" },
  { imgSrc: "/images/canada_4_logo_black_and_white_3.png" },
  { imgSrc: "/images/sida_logo_1.png" },
  { imgSrc: "/images/canada_4_logo_black_and_white_3.png" },
  { imgSrc: "/images/sida_logo_1.png" },
  { imgSrc: "/images/canada_4_logo_black_and_white_3.png" },
  { imgSrc: "/images/sida_logo_1.png" },
  { imgSrc: "/images/canada_4_logo_black_and_white_3.png" },
];

const imageFirst = images.slice(0, 5).map((imageRight, index) => {
  return (
    <img
      key={index + imageRight.imgSrc}
      src={imageRight.imgSrc}
      alt="partner logo"
    />
  );
});
const imageSecond = images.slice(5).map((imageRight, index) => {
  return (
    <img
      key={index + imageRight.imgSrc}
      src={imageRight.imgSrc}
      alt="partner logo"
    />
  );
});
export const OurPartnersSection = (props) => {
  return (
    <div className="ourPartnersMain">
      <SectionTitle
        margin="Margin"
        rectangularColor="Dark"
        color="Dark"
        title="our partners"
      />
      <Grid container spacing={0} className="ourPartnersWrapper">
        <Grid item xs={12} md>
          <div className="imgContainerFirst">{imageFirst}</div>
        </Grid>
      </Grid>
      <Grid container spacing={0} className="ourPartnersWrapper">
        <Grid item xs={12} md>
          <div className="imgContainerSecond">{imageSecond}</div>
        </Grid>
      </Grid>
    </div>
  );
};
