import React from "react";

export const LinkedInIcon = (props) => {
  return (
    <svg
      style={{ paddingBottom: "1px" }}
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16 16.8894H12.4444V10.7566C12.4444 9.79484 11.3885 8.99022 10.4476 8.99022C9.50658 8.99022 8.88889 9.79484 8.88889 10.7566V16.8894H5.33334V5.98672H8.88889V7.80383C9.4776 6.83038 10.9835 6.2023 12.0222 6.2023C14.2191 6.2023 16 8.05696 16 10.3024V16.8894ZM3.55555 16.8894H0V5.98672H3.55555V16.8894ZM1.77779 0.5354C2.75963 0.5354 3.55556 1.34895 3.55556 2.35251C3.55556 3.35607 2.75963 4.16962 1.77779 4.16962C0.795946 4.16962 8.90096e-06 3.35607 8.90096e-06 2.35251C8.90096e-06 1.34895 0.795946 0.5354 1.77779 0.5354Z"
        fill="#E4F1FA"
      />
    </svg>
  );
};
