import React from "react";
import "src/components/home_page/header_section/HomePageHeader.css";
import { ArrowRight } from "src/components/icons/ArrowRight";

export const HomePageHeader = (props) => {
  return (
    <div className="headerBackground">
      <div className="header">
        <div className="h1Container">
          <h1>
            Inform your work with U4’s quality research and practical guidance
          </h1>
        </div>
        <div className="secondaryHeadingText">
          Sustainable and inclusive development starts where corruption ends
        </div>
        <div className="headerLink">
          <div>Learn more ABOUT U4</div> <ArrowRight svgColor="white" />
        </div>
      </div>
    </div>
  );
};
