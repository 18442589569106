import React from "react";
import "./TagElement.css";

export const TagElement = (props) => {
  return (
    <div className="tagMain">
      <p className="tagElement">{props.title}</p>
    </div>
  );
};
