import React from "react";
import "./FeaturedArticleElement.css";

export const FeaturedArticleElement = (props) => {
  return (
    <div className="featuredArticleElemMain">
      <div className="featuredArticleElem">
        <div>
          <img
            className="featuredArticleImg"
            alt="articlepost"
            src={props.article.imgSrc}
          />
        </div>
        <div className="elementCategory">{props.article.category}</div>
        <div className="elementCategoryRectangular"></div>
        <h4>{props.article.articleHeader4}</h4>
        <p className="articleContent">{props.article.articleContent}</p>
        <p className="articleDate">{props.article.articleDate}</p>
      </div>
    </div>
  );
};
