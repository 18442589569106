import React from "react";
import "./PartnerSection.css";
import { SectionTitle } from "src/components/section_elements/SectionTitle";
import { PartnerElement } from "src/components/home_page/partner_section/PartnerElement";
// @ts-ignore
import Grid from "@material-ui/core/Grid";

const partnerElements = [
  {
    imgSrc: "/images/image_33.png",
    id: 0,
    title: "Online training",
    content:
      "We offer dynamic and time-efficient online courses. Be confident with anti-corruption initiatives in your work.",
    headingColor: "Light",
  },
  {
    imgSrc: "/images/image_34.png",
    id: 1,
    title: "Workshop events",
    content:
      "Our team can help bring momentum to processes. We gather different actors for informed discussions.",
    headingColor: "Light",
  },
  {
    imgSrc: "/images/image_35.png",
    id: 2,
    title: "Helpdesk for partners",
    content:
      "Send in your corruption-related questions about development programmes, sectors, or countries. A free research service.",
    headingColor: "Light",
  },
];
const partnerElement = partnerElements.map((partnerElement, index) => {
  return (
    <Grid className="partnerElementContainer" key={index} item md={4}>
      <PartnerElement
        key={index + partnerElement.title}
        partnerElement={partnerElement}
      />
    </Grid>
  );
});

export const PartnerSection = (props) => {
  return (
    <div className="partnerMain">
      <div className="partnerSection">
        <SectionTitle
          margin="Margin"
          rectangularColor="Light"
          color="Light"
          title="Become a partner"
        />
        <Grid spacing={3} container className="partnerWrapper">
          {partnerElement}
        </Grid>
      </div>
    </div>
  );
};
