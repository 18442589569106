import React from "react";
import "./PartnerElement.css";
export const PartnerElement = (props) => {
  return (
    <div className="partnerElemMain">
      <div className="partnerElem">
        <div>
          <img
            className="partnerImg"
            src={props.partnerElement.imgSrc}
            alt="partner"
          />
        </div>
        <h4 className={"headingUpperCase" + props.partnerElement.headingColor}>
          {props.partnerElement.title}
        </h4>
        <p className={"partnerContent" + props.partnerElement.headingColor}>
          {props.partnerElement.content}
        </p>
      </div>
    </div>
  );
};
