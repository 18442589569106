import React from "react";
import "./Icon2.css";

export const Icon2 = (props) => {
  return (
    <svg
      className="icon2"
      width="147"
      height="34"
      viewBox="0 0 147 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M82.7128 0.568726C83.7888 0.568726 84.5957 0.702046 85.2682 0.968687V2.70185C84.1923 2.30189 83.3853 2.16857 82.7128 2.16857C81.2334 2.16857 80.6955 3.50178 80.6955 5.23494C80.6955 6.96811 81.2334 8.30131 82.7128 8.30131C83.3853 8.30131 84.1923 8.16799 85.2682 7.76803V9.5012C84.5957 9.76784 83.7888 9.90116 82.7128 9.90116C79.8885 9.90116 79.0815 7.76803 79.0815 5.23494C79.0815 2.70185 80.023 0.568726 82.7128 0.568726Z"
        fill="#E4F1FA"
      />
      <path
        d="M91.9929 9.7678V5.9015H88.8995V9.7678H87.2856V0.835327H88.8995V4.30166H91.9929V0.835327H93.6068V9.7678H91.9929Z"
        fill="#E4F1FA"
      />
      <path
        d="M97.507 9.7678H95.8931V0.835327H98.8519C101.273 0.835327 101.945 1.90189 101.945 3.50174C101.945 4.5683 101.676 5.50154 100.6 5.9015C100.735 6.30147 101.004 6.70143 101.273 7.10139C101.676 7.90131 102.349 9.1012 102.752 9.7678H100.735L98.9864 6.30147H97.507V9.7678ZM97.507 4.5683H98.9864C100.062 4.5683 100.197 4.03502 100.197 3.50174C100.197 2.83513 100.062 2.43517 98.9864 2.43517H97.507V4.5683Z"
        fill="#E4F1FA"
      />
      <path
        d="M104.635 8.03467C105.173 8.03467 105.576 8.43463 105.576 8.96791C105.576 9.50119 105.173 9.90115 104.635 9.90115C104.097 9.90115 103.694 9.50119 103.694 8.96791C103.559 8.43463 104.097 8.03467 104.635 8.03467Z"
        fill="#E4F1FA"
      />
      <path
        d="M84.0578 20.1668H82.8474L80.83 15.6339V21.3667H79.2161V12.4342H81.099L83.5198 17.9003L85.9407 12.4342H87.8236V21.3667H86.2097V15.6339L84.0578 20.1668Z"
        fill="#E4F1FA"
      />
      <path
        d="M89.8408 21.3667V12.4342H91.4547V21.3667H89.8408Z"
        fill="#E4F1FA"
      />
      <path
        d="M97.238 12.3009C98.3139 12.3009 99.1209 12.4342 99.7933 12.7009V14.434C98.7174 14.0341 97.9104 13.9007 97.238 13.9007C95.7586 13.9007 95.2206 15.234 95.2206 16.9671C95.2206 18.7003 95.7586 20.0335 97.238 20.0335C97.9104 20.0335 98.7174 19.9002 99.7933 19.5002V21.2334C99.1209 21.5 98.3139 21.6333 97.238 21.6333C94.4136 21.6333 93.6067 19.5002 93.6067 16.9671C93.6067 14.3007 94.5481 12.3009 97.238 12.3009Z"
        fill="#E4F1FA"
      />
      <path
        d="M106.518 21.3667V17.5004H103.425V21.3667H101.811V12.4342H103.425V15.9005H106.518V12.4342H108.132V21.3667H106.518Z"
        fill="#E4F1FA"
      />
      <path
        d="M115.529 17.5004H111.898V19.7668H115.798V21.3667H110.149V12.4342H115.798V14.034H111.898V15.9005H115.529V17.5004Z"
        fill="#E4F1FA"
      />
      <path
        d="M119.564 19.7668H123.329V21.3667H117.95V12.4342H119.564V19.7668Z"
        fill="#E4F1FA"
      />
      <path
        d="M130.995 18.567C130.995 19.9002 130.323 21.5 127.499 21.5C126.692 21.5 125.347 21.2334 124.809 20.9667V19.3669C125.212 19.6335 126.288 20.0335 127.499 20.0335C128.844 20.0335 129.247 19.3669 129.247 18.7003C129.247 17.9004 128.844 17.767 127.768 17.6337C125.616 17.3671 124.809 16.7005 124.809 15.1006C124.809 14.0341 125.347 12.3009 128.037 12.3009C129.113 12.3009 129.92 12.5675 130.592 12.8342V14.5674C129.382 14.0341 128.709 13.9007 128.037 13.9007C126.826 13.9007 126.557 14.434 126.557 15.1006C126.557 15.7672 127.095 15.9006 128.171 16.0339C130.054 16.1672 130.995 16.8338 130.995 18.567Z"
        fill="#E4F1FA"
      />
      <path
        d="M138.124 17.5004H134.492V19.7668H138.393V21.3667H132.744V12.4342H138.393V14.034H134.492V15.9005H138.124V17.5004Z"
        fill="#E4F1FA"
      />
      <path
        d="M145.655 21.3667L142.024 15.7672V21.3667H140.41V12.4342H141.755L145.386 18.0337V12.4342H147V21.3667H145.655Z"
        fill="#E4F1FA"
      />
      <path
        d="M79.3506 32.9655V24.0331H80.9645V32.9655H79.3506Z"
        fill="#E4F1FA"
      />
      <path
        d="M88.4962 32.9655L84.8649 27.3661V32.9655H83.251V24.0331H84.5959L88.2272 29.6325V24.0331H89.8411V32.9655H88.4962Z"
        fill="#E4F1FA"
      />
      <path
        d="M97.9105 30.1658C97.9105 31.499 97.2381 33.0989 94.4137 33.0989C93.6068 33.0989 92.2618 32.8323 91.7239 32.5656V30.9658C92.1274 31.2324 93.2033 31.6324 94.4137 31.6324C95.7587 31.6324 96.1621 30.9658 96.1621 30.2992C96.1621 29.4992 95.7587 29.3659 94.6827 29.2326C92.5308 28.966 91.7239 28.2994 91.7239 26.6995C91.7239 25.6329 92.2618 23.8998 94.9517 23.8998C96.0276 23.8998 96.8346 24.1664 97.5071 24.4331V26.1662C96.2966 25.6329 95.6242 25.4996 94.9517 25.4996C93.7413 25.4996 93.4723 26.0329 93.4723 26.6995C93.4723 27.3661 94.0102 27.4994 95.0862 27.6328C96.9691 27.7661 97.9105 28.4327 97.9105 30.1658Z"
        fill="#E4F1FA"
      />
      <path
        d="M103.021 25.6329V32.9655H101.407V25.6329H99.1208V24.0331H105.308V25.6329H103.021Z"
        fill="#E4F1FA"
      />
      <path
        d="M107.056 32.9655V24.0331H108.67V32.9655H107.056Z"
        fill="#E4F1FA"
      />
      <path
        d="M114.453 25.6329V32.9655H112.839V25.6329H110.553V24.0331H116.739V25.6329H114.453Z"
        fill="#E4F1FA"
      />
      <path
        d="M123.464 29.8992V24.0331H125.078V30.0325C125.078 32.6989 122.926 33.0989 121.716 33.0989C120.64 33.0989 118.353 32.6989 118.353 30.0325V24.0331H119.967V29.8992C119.967 31.3657 121.043 31.499 121.581 31.499C122.254 31.499 123.464 31.3657 123.464 29.8992Z"
        fill="#E4F1FA"
      />
      <path
        d="M130.592 25.6329V32.9655H128.978V25.6329H126.692V24.0331H132.878V25.6329H130.592Z"
        fill="#E4F1FA"
      />
      <path
        d="M139.872 29.0993H136.241V31.3657H140.141V32.9655H134.492V24.0331H140.141V25.6329H136.241V27.6327H139.872V29.0993Z"
        fill="#E4F1FA"
      />
      <path
        d="M13.4492 0.0354004C17.215 0.0354004 20.5773 0.435362 22.9982 1.50193V9.10119C18.4254 7.2347 15.7356 7.10138 13.4492 7.10138C9.01098 7.10138 7.53156 10.9677 7.53156 16.9671C7.53156 22.8332 9.01098 26.8328 13.4492 26.8328C15.8701 26.8328 18.4254 26.6995 22.9982 24.833V32.4323C20.5773 33.4988 17.0805 33.8988 13.4492 33.8988C3.36231 33.8988 0 26.9661 0 16.9671C0 6.96806 3.22781 0.0354004 13.4492 0.0354004Z"
        fill="#E4F1FA"
      />
      <path
        d="M45.7273 29.2326H40.4821L34.968 16.5671L35.2369 32.9656H27.9744V0.835327H35.6404L43.172 18.4336L50.7036 0.835327H58.3696V33.0989H51.107L51.376 16.5671L45.7273 29.2326Z"
        fill="#E4F1FA"
      />
      <path
        d="M64.4219 33.0989V0.835327H71.8189V33.0989H64.4219Z"
        fill="#E4F1FA"
      />
    </svg>
  );
};
