import React from "react";
import { SectionTitle } from "../section_elements/SectionTitle";
import "./FooterSection.css";
import Grid from "@material-ui/core/Grid";
import { FacebookIcon } from "src/components/icons/FacebookIcon";
import { LinkedInIcon } from "src/components/icons/LinkedInIcon";
import { TwitterIcon } from "src/components/icons/TwitterIcon";
import { MailIcon } from "src/components//icons/MailIcon";
import { Icon2 } from "../icons/Icon2";

const icons = [
  { iconSrc: <FacebookIcon /> },
  { iconSrc: <LinkedInIcon /> },
  { iconSrc: <TwitterIcon /> },
  { iconSrc: <MailIcon /> },
];

const footerLinks = [
  { title: "Research topics" },
  { title: "Blog" },
  { title: "Online courses" },
  { title: "Privacy policy" },
  { title: "Contact" },
  { title: "Guides and policies" },
];
const icon = icons.map((icon, index) => {
  return (
    <div className="followIconContainer" key={index}>
      {icon.iconSrc}
    </div>
  );
});

const footerLinkLeft = footerLinks.slice(0, 3).map((footerLinkLeft, index) => {
  return <div key={index + footerLinkLeft.title}>{footerLinkLeft.title}</div>;
});
const footerLinkRight = footerLinks.slice(3).map((footerLinkRight, index) => {
  return (
    <div className="footerLinkRight" key={index + footerLinkRight.title}>
      {footerLinkRight.title}
    </div>
  );
});
export const FooterSection = (props) => {
  return (
    <div className="footerMain">
      <div className="subscribeSectionMain">
        <div className="subscribeSectionWrapper">
          <Grid container spacing={4}>
            <Grid item xs={12} md={8}>
              <SectionTitle
                margin="NoMargin"
                rectangularColor="White"
                color="Light"
                title="Subscribe to our emails"
              />
              <form className="emailForm" action="#">
                <input
                  type="email"
                  name="email"
                  id="subscribeEmail"
                  placeholder="YOUR EMAIL ADRESS"
                />
                <input type="submit" name="sumbitEmail" id="submitEmail" />
              </form>
            </Grid>
            <Grid item xs={12} md={4}>
              <SectionTitle
                margin="NoMargin"
                rectangularColor="White"
                color="Light"
                title="Follow us"
              />
              <div className="followIconContainerMain">{icon}</div>
            </Grid>
          </Grid>
        </div>
      </div>
      <div className="footerSectionBackground">
        <div className="footerSectionMain">
          <div className="footerSectionWrapper">
            <Grid container spacing={4}>
              <Grid item xs={12} md={8}>
                <div className="IconTextContainer">
                  <div>
                    <Icon2 />
                  </div>
                  <div className="footerText">
                    The U4 Anti-Corruption Resource Centre works to reduce the
                    harmful impact of corruption on society. U4 is a permanent
                    centre at Chr. Michelsen Institute in Norway.
                  </div>
                </div>
              </Grid>
              <Grid className="footerLinks" item xs={12} md={4}>
                <div className="footerLinkContainer">{footerLinkLeft}</div>
                <div className="footerLinkContainer">{footerLinkRight}</div>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
    </div>
  );
};
