import React from "react";
import "./BrowseSection.css";
import { SectionTitle } from "src/components/section_elements/SectionTitle";
import { Grid } from "@material-ui/core";
import { PartnerElement } from "../partner_section/PartnerElement";
import { ArrowRight } from "../../icons/ArrowRight";

const partnerElements = [
  {
    title: "ANTI-CORRUPTION AGENCIES",
    content:
      "No more than 20 words – semper quis lectus nulla at volutpat diam ut venenatis tellus in metus vulputate eu scelerisque . . .",
    headingColor: "Dark",
    dateUpdated: "Updated 09 Apr 2021",
  },
  {
    title: "ANTI-CORRUPTION BASICS",
    content:
      "What is corruption? The basics of corruption and anti-corruption efforts for sustainable and inclusive development",
    headingColor: "Dark",
    dateUpdated: "Updated 09 Apr 2021",
  },
  {
    title: "ANTI-CORRUPTION COURTS",
    content: "Design and performance of specialised anti-corruption courts",
    headingColor: "Dark",
    dateUpdated: "Updated 09 Apr 2021",
  },
  {
    title: "AUDITING AND FINANCIAL CONTROL",
    content:
      "Corruption and anti-corruption measures related to public auditing and financial control systems",
    headingColor: "Dark",
    dateUpdated: "Updated 09 Apr 2021",
  },
  {
    title: "BUDGET PROCESS",
    content: "Addressing corruption in the public budget process",
    headingColor: "Dark",
    dateUpdated: "Updated 09 Apr 2021",
  },
  {
    title: "COVID-19 AND CORRUPTION",
    content: "Strengthening anti-corruption efforts during the pandemic",
    headingColor: "Dark",
    dateUpdated: "Updated 09 Apr 2021",
  },
];
const partnerElement = partnerElements.map((partnerElement, index) => {
  return (
    <Grid key={index} item md={4} sm={12}>
      <div className="cardElemMain">
        <div className="cardElem">
          <PartnerElement
            key={index + partnerElement.heading}
            partnerElement={partnerElement}
          />
          <div className="dateUpdated">{partnerElement.dateUpdated}</div>
        </div>
      </div>
    </Grid>
  );
});
export const BrowseSection = (props) => {
  return (
    <div className="browseMain">
      <div className="browseSection">
        <SectionTitle
          margin="Margin"
          rectangularColor="Dark"
          color="Dark"
          title="browse our topics"
        />
        <div className="browseWrapper">
          <Grid container alignItems="stretch" spacing={4}>
            {partnerElement}
          </Grid>
        </div>
      </div>
      <div className="browseSectionFooter">
        <div className="sectionFooterLeft">
          View ALL
          <ArrowRight svgColor="#333333" />
        </div>
      </div>
    </div>
  );
};
